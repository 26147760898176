$font-size-base: 0.75rem; // Assumes the browser default, typically 16px

@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,400,500,600,700);

@import "ember-bootstrap/bootstrap";

/*------------------------------------
- COLOR primary
------------------------------------*/

$primary_t1: #f05823;
$primary_t2: #d1400e;
$primary_t3: #fac8b7;
$primary_t4: #fbdace;
$primary_t5: #8e2c09;
$primary_t6: #c33c0d;
$primary_t7: #e0450f;
$primary_t8: #5f1d06;
$primary_t9: #fde8e1;

$primary_shadow: rgba(240, 88, 35, 0.5);
$primary_text_color_dark_bright: #fff;

body {
  font-family: "Source Sans Pro",sans-serif;
  margin: 0;
  color: #3b3b3b;
  font-weight: 300;
  font-size: 17px; 
}

label {
  font-weight: 300;
}


.alert-primary {
  color: $primary_t5;
  background-color: $primary_t9;
  border-color: $primary_t4;
}

.alert-primary hr {
  border-top-color: $primary_t3;
}

.alert-primary .alert-link {
  color: $primary_t8;
}

.badge-primary {
  color: $primary_text_color_dark_bright;
  background-color: $primary_t1;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: $primary_text_color_dark_bright;
  background-color: $primary_t2;
}

.bg-primary {
  background-color: $primary_t1 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: $primary_t2 !important;
}

.border-primary {
  border-color: $primary_t1 !important;
}

.btn-primary {
  color: $primary_text_color_dark_bright;
  background-color: $primary_t1;
  border-color: $primary_t1;
}

.btn-primary:hover {
  color: $primary_text_color_dark_bright;
  background-color: $primary_t7;
  border-color: $primary_t2;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem $primary_shadow;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: $primary_text_color_dark_bright;
  background-color: $primary_t1;
  border-color: $primary_t1;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: $primary_text_color_dark_bright;
  background-color: $primary_t2;
  border-color: $primary_t6;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem $primary_shadow;
}

.btn-outline-primary {
  color: $primary_t1;
  background-color: transparent;
  border-color: $primary_t1;
}

.btn-outline-primary:hover {
  color: $primary_text_color_dark_bright;
  background-color: $primary_t1;
  border-color: $primary_t1;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem $primary_shadow;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: $primary_t1;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: $primary_text_color_dark_bright;
  background-color: $primary_t1;
  border-color: $primary_t1;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem $primary_shadow;
}

.list-group-item-primary {
  color: $primary_t5;
  background-color: $primary_t4;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: $primary_t5;
  background-color: $primary_t3;
}

.list-group-item-primary.list-group-item-action.active {
  color: $primary_text_color_dark_bright;
  background-color: $primary_t5;
  border-color: $primary_t5;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: $primary_t4;
}

.table-hover .table-primary:hover {
  background-color: $primary_t3;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: $primary_t3;
}

.text-primary {
  color: $primary_t1 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: $primary_t2 !important;
}

.nav > .active, .navbar-nav > .active > a {
    color: $primary_t1 !important;
    }
    .nav > a:hover, .nav-item > a:hover {
    color: $primary_text_color_dark_bright !important;
    background-color: $primary_t1;
  }

.header1 {
  padding-top: 10px;
  padding-bottom: 10px;  
}
.header2 {
  padding-top:10px;
  padding-bottom:10px;

  border-top: 4px solid #ddd;
  background-color: #f7f7f7;
}

.bg2 {
  padding: 4px;
}
.text-bold1 {
    font-size:1.1em;
    font-weight:bold;
}

.my-nav {
    border-bottom: 2px solid $primary_t1;    
}

.my-nav a {
  font-size: 1.2em;
  font-weight: 300;
  padding-left: 10px;
  padding-right: 16px;
  padding-bottom: 5px;
  padding-top: 5px;
  background-color: transparent;
  border: 0;
  margin-bottom: 2px;
  display: inline-block;
  text-decoration: none;
  color: #2b2b2b;
} 
.my-nav a:hover {
    background-color:whitesmoke;
    text-decoration:none;
}

.my-nav a.active{
    color: white;
    background-color: $primary_t1;
}

.text-p {
    
}

.page-input {
  width: 60px !important;
  text-align: center !important;
  border-radius: 0 !important;
  height: 100%;
  border: 1px solid #dee2e6;
  font-weight:bold;
}
.page-link {
  color: #6e6e6e;
  font-size:13px;
}

.bmc-logo {
  display: block; 
  height: 60px;
  margin: initial;
  background-image: url('/images/bmc-logo.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.powered-by { 
}

.btn-primary {
    background-color: #0f0f0f;
    border-color: #090909;
    color: #fff;
    border-radius:0;
}


.btn-primary:hover {
  background-color: #0f0f0f;
  border-color: #090909;
  color: #fff;
  border-radius: 0;
}



.btn-secondary {
  background-color: #0f0f0f;
  border-color: #090909;
  color: #fff;
  border-radius: 0;
}


.btn-secondary:hover {
  background-color: #0f0f0f;
  border-color: #090909;
  color: #fff;
  border-radius: 0;
}
 
select.form-control {
    font-size:13px;
}
input.form-control {
    font-size: 13px;
}
.table.table-sm thead tr th {
    padding:2px;
    font-weight:500;
    font-size:16px;
}
.table.table-sm tbody tr td {
    font-size:15px;
    padding-top: 1px;
}

#propertiesPanel div.loader {
    display:none;
}

#propertiesPanel table.table {
  opacity: 1;
}

#propertiesPanel.loading div.loader  {
    display:block;
    z-index:4;
}

#propertiesPanel.loading table,table {
  opacity: 0.5;
}

#propertiesTableHeader[data-asc='false'][data-orderby='category'] th[data-col='category'] div:after,
#propertiesTableHeader[data-asc='false'][data-orderby='esn'] th[data-col='esn'] div:after,
#propertiesTableHeader[data-asc='false'][data-orderby='related-esn'] th[data-col='related-esn'] div:after,
#propertiesTableHeader[data-asc='false'][data-orderby='model-or-part-no'] th[data-col='model-or-part-no'] div:after,
#propertiesTableHeader[data-asc='false'][data-orderby='serial#'] th[data-col='serial#'] div:after,
#propertiesTableHeader[data-asc='false'][data-orderby='description'] th[data-col='description'] div:after,
#propertiesTableHeader[data-asc='false'][data-orderby='manufacturer'] th[data-col='manufacturer'] div:after,
#propertiesTableHeader[data-asc='false'][data-orderby='model-number'] th[data-col='model-number'] div:after,
#propertiesTableHeader[data-asc='false'][data-orderby='physical-status'] th[data-col='physical-status'] div:after,
#propertiesTableHeader[data-asc='false'][data-orderby='claimed-by'] th[data-col='claimed-by'] div:after {
  content: '▼';
  color: #999;
  float:right;
}
#propertiesTableHeader[data-asc='true'][data-orderby='catigory'] th[data-col='catigory'] div:after,
#propertiesTableHeader[data-asc='true'][data-orderby='esn'] th[data-col='esn'] div:after,
#propertiesTableHeader[data-asc='true'][data-orderby='related-esn'] th[data-col='related-esn'] div:after,
#propertiesTableHeader[data-asc='true'][data-orderby='model-or-part-no'] th[data-col='model-or-part-no'] div:after,
#propertiesTableHeader[data-asc='true'][data-orderby='serial#'] th[data-col='serial#'] div:after,
#propertiesTableHeader[data-asc='true'][data-orderby='description'] th[data-col='description'] div:after,
#propertiesTableHeader[data-asc='true'][data-orderby='manufacturer'] th[data-col='manufacturer'] div:after,
#propertiesTableHeader[data-asc='true'][data-orderby='model-number'] th[data-col='model-number'] div:after,
#propertiesTableHeader[data-asc='true'][data-orderby='physical-status'] th[data-col='physical-status'] div:after,
#propertiesTableHeader[data-asc='true'][data-orderby='claimed-by'] th[data-col='claimed-by'] div:after {
  content: '▲';
  color: #999;
  float:right;
}

#propertiesTableHeader th[data-col='related-esn'],
#propertiesTableHeader th[data-col='claimed-by'],
#propertiesTableHeader th[data-col='model-or-part-no'] {
  white-space: nowrap;
}

div.message[data-records] {
  display: none;
}
div.message[data-records='0'] {
    display:block; 
}

input[type=search] {
  -webkit-appearance: none;
}

.modal-dialog
{
    width: 50px !important;
}
